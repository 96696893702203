import moment from 'moment';
import {DISPLAY_DATE_FORMAT,DISPLAY_TIME_FORMAT,DATE_INPUT_FORMAT,/*TIMEZONE_OFFSET,*/MESSAGE_TYPE_ERROR} from '@/shared/consts';
import lang from '@/shared/lang';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

export function log (content){
    // eslint-disable-next-line no-console
    console.log(content);
}

export function getMomentDate(dateUTC){
    return moment.utc(dateUTC);
    //return moment.parseZone(dateUTC + "" + TIMEZONE_OFFSET);
}

export function getDateInDisplayFormat(date, format = DISPLAY_DATE_FORMAT){
    return date.format(format)
}

export function createDateString(dateUTC, friendly = true){
    let date = getMomentDate(dateUTC);
    let dateStr = "";
    if(friendly){
        if(date.isSame(moment(), 'day')){
            let timeStr = getDateInDisplayFormat(date, DISPLAY_TIME_FORMAT);
            dateStr = timeStr !== "00:00" ? getDateInDisplayFormat(date, DISPLAY_TIME_FORMAT) + " " + lang.date_today : lang.date_today;
            log(["DATE TIME FORMATTING: ", date, dateUTC, DISPLAY_TIME_FORMAT]);
        }
        else if(date.isSame(moment().subtract(1, 'day'), 'day')){
            dateStr = lang.date_yesterday;
        }
        else
        {
            dateStr = getDateInDisplayFormat(date);
        }
    }
    else
    {
        dateStr = getDateInDisplayFormat(date);
    }
    return dateStr;
}

export function createDateTimeString(dateUTC, friendly = true){
    let date = getMomentDate(dateUTC);
    let timeStr = getDateInDisplayFormat(date, DISPLAY_TIME_FORMAT);
    let dateStr = createDateString(dateUTC, friendly);
    return timeStr + " - " + dateStr;
}

export function convertDateForInputValue(dateUTC){
    if(dateUTC){
        let date = getMomentDate(dateUTC);
        return getDateInDisplayFormat(date,DATE_INPUT_FORMAT);
    }
    else
    {
        return "";
    }

}

export function convertDateFromInputToUTC(inputDate){
    let date = moment.utc(inputDate);
    return date.format();
}

export function stripMomentInvalidDateMessage(date){
    return ["Invalid date"].includes(date) ? "" : date;
}

export function showDefaultServerErrorMessage(ref = ""){
    store.dispatch('addSystemMessageAction', {
        'type' : MESSAGE_TYPE_ERROR,
        'message' : (ref ? ref + " : "  : "") + lang.message_bad_server_response
    });
}

export function makeStringURLsafe(string){
    return encodeURI(string);
}

export function getUUID(){
    return uuidv4();
}

export function localScrollToDomElement(domElement){
    const topPos = (domElement.getBoundingClientRect().top - 35) + window.pageYOffset;
    window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth' // smooth scroll
    })
}

export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

// add all util functions as created here if someone wants to import them all
export default {log, getMomentDate, getDateInDisplayFormat, createDateString, createDateTimeString, convertDateForInputValue, convertDateFromInputToUTC, showDefaultServerErrorMessage, makeStringURLsafe, getUUID, localScrollToDomElement, debounce}
