<template>
    <component :is="'Formelement_' + elementData.type"
                :element="elementData"
                :classString="elementClassString"
                :invertDisplay="invertDisplay"
                v-model="elementValue"
                v-show="shouldShow"
                ref="element"
                v-on:user-input="onUserInput"></component>
</template>

<script>
    import {log} from "@/shared/utils";

    import Formelement_text from "@/components/form/formelement_text";
    import Formelement_textarea from "@/components/form/formelement_textarea";
    import Formelement_wysiwyg from "@/components/form/formelement_wysiwyg";
    import Formelement_password from "@/components/form/formelement_password";
    import Formelement_date from "@/components/form/formelement_date";
    import Formelement_select from "@/components/form/formelement_select";
    import Formelement_checkbox from "@/components/form/formelement_checkbox";
    import Formelement_radio from "@/components/form/formelement_radio";
    import Formelement_file from "@/components/form/formelement_file";

    import Formelement_disabled from "@/components/form/formelement_disabled";

    import Formelement_textpicker_edit from "@/components/form/formelement_textpicker_edit";
    import Formelement_textpicker_select from "@/components/form/formelement_textpicker_select";
    import Formelement_textpicker_view from "@/components/form/formelement_textpicker_view";
    import Formelement_textpicker_value from "@/components/form/formelement_textpicker_value";
    import Formelement_textareapicker_edit from "@/components/form/formelement_textareapicker_edit";
    import Formelement_textareapicker_select from "@/components/form/formelement_textareapicker_select";
    import Formelement_textareapicker_view from "@/components/form/formelement_textareapicker_view";
    import Formelement_textareapicker_value from "@/components/form/formelement_textareapicker_value";
    import Formelement_mediapicker_edit from "@/components/form/formelement_mediapicker_edit";
    import Formelement_mediapicker_select from "@/components/form/formelement_mediapicker_select";
    import Formelement_mediapicker_view from "@/components/form/formelement_mediapicker_view";
    import Formelement_mediapicker_value from "@/components/form/formelement_mediapicker_value";

    export default {
        name: "Formelement",
        components: {
            Formelement_text,
            Formelement_textarea,
            Formelement_wysiwyg,
            Formelement_password,
            Formelement_date,
            Formelement_select,
            Formelement_checkbox,
            Formelement_radio,
            Formelement_file,
            Formelement_disabled,
            Formelement_textpicker_edit,
            Formelement_textpicker_select,
            Formelement_textpicker_view,
            Formelement_textpicker_value,
            Formelement_textareapicker_edit,
            Formelement_textareapicker_select,
            Formelement_textareapicker_view,
            Formelement_textareapicker_value,
            Formelement_mediapicker_edit,
            Formelement_mediapicker_select,
            Formelement_mediapicker_view,
            Formelement_mediapicker_value,
        },
        data() {
            return {
                overrideHidden: false
            };
        },
        props: {
            elementData: {
                type: Object
            },
            value: {
                type: [String, Array, File, Boolean, Number]
            },
            invertDisplay: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            validateElement() {
                return this.$refs.element.validate();
            },
            clearElement() {
                this.$refs.element.clear();
            },
            clearError() {
                this.$refs.element.resetError();
            },
            onUserInput(){
                log("Element parent detects user change");
                this.$emit('user-input');
            },
            setOverrideHidden() {
                if (
                    Object.prototype.hasOwnProperty.call(this.elementData, 'autoSelectAndHideOnSingleOption')
                    && this.elementData.autoSelectAndHideOnSingleOption
                    && Object.prototype.hasOwnProperty.call(this.elementData, 'options')
                    && this.elementData.options.length === 1
                ) {
                    this.overrideHidden = true;
                } else {
                    this.overrideHidden = false;
                }
            }
        },
        computed: {
            elementValue: {
                get() { return this.value },
                set(elementValue) {
                   this.$emit('input', elementValue);
                }
            },
            elementClassString() {
                let classString = "";

                // is disabled?
                classString += this.elementData.disabled ? "disabled " : "";
                // is readonly?
                classString += this.elementData.readonly ? "readonly " : "";
                // has errors?
                classString += this.elementData.errors ? "error " : "";
                // is indented
                classString += this.elementData.indent ? "form-input-indent " : "";

                // custom class string
                classString += this.elementData.classString ? this.elementData.classString : "";

                return classString;
            },
            shouldShow() {
                if (this.overrideHidden) {
                    return false;
                }

                return Object.prototype.hasOwnProperty.call(this.elementData, 'hidden')
                            ? !this.elementData.hidden
                            : true;
            }
        },
        watch: {
            overrideHidden(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.elementValue = this.elementData.options[0].value;
                }
            },
            elementData: {
                handler(newVal, oldVal) {
                    log(['Element data updated!', newVal, oldVal]);
                    this.setOverrideHidden();
                },
                deep: true
            }
        },
        mounted() {
            this.setOverrideHidden();
        }
    }
</script>
