// define a mixin object
import {log, getMomentDate, getDateInDisplayFormat, convertDateFromInputToUTC} from "@/shared/utils";
import {EMAIL_REGEX,PASSWORD_REGEX,PASSWORD_NUMBER_UNIQUE_CHARACTERS} from "@/shared/consts";
import lang from "@/shared/lang";
import moment from 'moment';

export const formElementMixin = {
    props: {
        invertDisplay: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            error: false,
            startValue: "",
            doFocusWatch: false,
        }
    },
    methods: {
        /**
         * MISC
         */
        clear() {
            document.getElementById(this.element.name).value = "";
        },
        toggleLockedState(){
            this.element.locked = !this.element.locked;
        },

        /**
         * Validation methods
         * @returns {boolean}
         */
        validate(showErrors = true) {
            //log("Validating " + this.element.name + " " + this.value);

            if ("validate" in this.element) {

                let validateType = (typeof this.element.validate['type']) === 'function' ? this.element.validate.type() : this.element.validate.type;

                //log("Validate type " + this.element.label + " " + this.element.name + " " + validateType);

                if (validateType === "string") {
                    return this.validateAsString(showErrors);
                } else if (validateType === "password") {
                    return this.validateAsPassword(showErrors);
                } else if (validateType === "email") {
                    return this.validateAsEmail(showErrors);
                } else if (validateType === "date") {
                    return this.validateAsDate(showErrors);
                } else if (validateType === "file") {
                    return this.validateAsFile(showErrors);
                } else if (validateType === "select") {
                    return this.validateAsSelect(showErrors);
                } else if (validateType === "picker") {
                    return this.validateAsPicker(showErrors);
                } else {
                    log(["ValidateType not matched"]);
                }

                // if all else fails pass validation
                // to keep things moving.
                return true;
            } else {
                //log("No validation rules set " + this.element.name);

                // if no validation rules set, pass validation
                return true;
            }
        },
        validateAsString(showErrors) {
            // remove HTML tags from string before validation
            this.validateValue = this.value !== undefined ? this.value.trim().replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", "") : "";

            log(["Validating string", this.element.name, this.value, this.validateValue]);


            // check for any content
            this.element.validate.hasContent = (this.validateValue.length > 0);

            // check for required
            if (( (typeof this.element.validate['required']) === 'function' && this.element.validate.required() ) || ((typeof this.element.validate['required']) !== 'function' && this.element.validate.required )) {
                // return false if no string length
                if (this.validateValue.length < 1) {
                    this.showError(lang.validate_required, showErrors);
                    return false;
                }
            }

            // check length
            // min
            if (parseInt(this.element.validate.min) > 0) {
                if (this.validateValue.length < parseInt(this.element.validate.min)) {
                    this.showError(lang.validate_string_min.replace("%d%", this.element.validate.min), showErrors);
                    return false;
                }
            }

            // max
            if (parseInt(this.element.validate.max) > 0) {
                if (this.validateValue.length > parseInt(this.element.validate.max)) {
                    this.showError(lang.validate_string_max.replace("%d%", this.element.validate.max), showErrors);
                    return false;
                }
            }

            // if we have got this far then we haven't hit a validation error
            this.resetError();
            return true;
        },
        validateAsPassword(showErrors) {
            // remove HTML tags from string before validation
            log(["Validating password (first as string)", this.element.name, this.value]);

            if(!this.validateAsString(showErrors)){
                return false;
            }

            // check password format
            log(["Testing password", [...new Set(this.value.split(""))], [...new Set(this.value.split(""))].length, PASSWORD_REGEX, PASSWORD_REGEX.test(String(this.value))]);
            if ([...new Set(this.value.split(""))].length < PASSWORD_NUMBER_UNIQUE_CHARACTERS) {
                this.showError(lang.validate_password_different_characters.replace("%d%", PASSWORD_NUMBER_UNIQUE_CHARACTERS), showErrors);
                return false;
            }

            if(!PASSWORD_REGEX.test(String(this.value))){
                this.showError(lang.validate_password_types_of_characters, showErrors);
                return false;
            }

            // if we have got this far then we haven't hit a validation error
            this.resetError();
            return true;
        },

        validateAsEmail(showErrors) {
            // remove HTML tags from string before validation
            log(["Validating email", this.element.name, this.value]);

            // check for any content
            this.element.validate.hasContent = (this.value.length > 0);

            // check for required
            if (( (typeof this.element.validate['required']) === 'function' && this.element.validate.required() ) || ((typeof this.element.validate['required']) !== 'function' && this.element.validate.required )) {
                // return false if no string length
                if (this.value.length < 1) {
                    this.showError(lang.validate_required, showErrors);
                    return false;
                }
            }

            // check email format
            log(["Testing email", EMAIL_REGEX.test(String(this.value).toLowerCase()), EMAIL_REGEX]);
            if (!EMAIL_REGEX.test(String(this.value).toLowerCase())) {
                this.showError(lang.validate_email_format, showErrors);
                return false;
            }

            // if we have got this far then we haven't hit a validation error
            this.resetError();
            return true;
        },
        validateAsDate(showErrors) {
            log("Validating date " + this.element.name + " " + this.value);

            // check for any content
            this.element.validate.hasContent = (this.value !== undefined && this.value.length > 0);

            // check for required
            if (( (typeof this.element.validate['required']) === 'function' && this.element.validate.required() ) || ((typeof this.element.validate['required']) !== 'function' && this.element.validate.required )) {
                // return false if no string length
                if (this.value === undefined || this.value.length < 1) {
                    this.showError(lang.validate_required, showErrors);
                    return false;
                }
            }

            let compareDate = false;
            let thisDate = getMomentDate(convertDateFromInputToUTC(this.value));

            // check for min
            if (this.element.validate.min) {

                // check for string based min otherwise assume it is a UTC date format
                if (this.element.validate.min === "today") {
                    compareDate = moment();
                } else if (this.element.validate.min === "tomorrow") {
                    compareDate = moment().add(1, 'day');
                } else {
                    compareDate = getMomentDate(this.element.validate.min);
                }

                if (thisDate.isBefore(compareDate, 'day')) {
                    this.showError(lang.validate_date_min.replace("%d%", getDateInDisplayFormat(compareDate)), showErrors);
                    return false;
                }
            }

            // check for min
            if (this.element.validate.max) {

                // check for string based min otherwise assume it is a UTC date format
                if (this.element.validate.max === "today") {
                    compareDate = moment();
                } else if (this.element.validate.max === "yesterday") {
                    compareDate = moment().subtract(1, 'day');
                } else {
                    compareDate = getMomentDate(this.element.validate.max);
                }

                if (thisDate.isAfter(compareDate, 'day')) {
                    this.showError(lang.validate_date_max.replace("%d%", getDateInDisplayFormat(compareDate)), showErrors);
                    return false;
                }
            }

            // check for comparison
            // TODO: COMPARISON FOR OTHER FIELD TYPES
            if(Object.prototype.hasOwnProperty.call(this.element.validate, "compare") && Object.prototype.hasOwnProperty.call(this.element.validate.compare, "elementName") && Object.prototype.hasOwnProperty.call(this.element.validate.compare, "comparison")) {
                //log(["Compare", this.element.validate.compare.elementName, document.getElementById(this.element.validate.compare.elementName)]);
                let compareElement = document.getElementById(this.element.validate.compare.elementName);

                //log(["Comparison", compareElement, compareElement.value]);

                compareDate = getMomentDate(convertDateFromInputToUTC(compareElement.value));

                // TODO: COMPARISON FOR OTHER COMPARISON TYPES
                if(this.element.validate.compare.comparison === ">="){
                    if(thisDate.isSameOrBefore(compareDate, 'day')){
                        this.showError(lang.validate_date_compare_after.replace("%s%", this.element.validate.compare.elementLabel), showErrors);
                        return false;
                    }
                }
            }

            // if we have got this far then we haven't hit a validation error
            this.resetError();
            return true;
        },
        validateAsFile(showErrors) {
            log("Validating file " + this.element.name + " " + this.value);

            // check for any content
            this.element.validate.hasContent = (this.value !== undefined && this.value.length > 0);

            // check for required
            if (( (typeof this.element.validate['required']) === 'function' && this.element.validate.required() ) || ((typeof this.element.validate['required']) !== 'function' && this.element.validate.required )) {
                // return false if no string length
                if (this.value === undefined || this.value.length < 1) {
                    this.showError(lang.validate_required, showErrors);
                    return false;
                }
            }

            // if we have got this far then we haven't hit a validation error
            this.resetError();
            return true;
        },
        validateAsSelect(showErrors) {
            log(["Validating select", this.element.name, this.value]);

            // check for any content
            this.element.validate.hasContent = (this.value !== undefined && this.value.trim().length > 0);

            // check for required
            if (( (typeof this.element.validate['required']) === 'function' && this.element.validate.required() ) || ((typeof this.element.validate['required']) !== 'function' && this.element.validate.required )) {
                // return false if no string length
                if (this.value === undefined || this.value.trim().length < 1) {
                    this.showError(lang.validate_required, showErrors);
                    return false;
                }
            }

            // if we have got this far then we haven't hit a validation error
            this.resetError();
            return true;
        },
        validateAsPicker(showErrors) {
            log(["Validating picker", this.element.name, this.element.selectedOptions]);

            // check for any content
            if(this.element.isAdvertContentEdit) {
                this.element.validate.hasContent = (this.element.value);
            }
            else {
                this.element.validate.hasContent = (this.element.selectedOptions && Array.isArray(this.element.selectedOptions) && this.element.selectedOptions.length > 0);
            }

            // check for required
            if (( (typeof this.element.validate['required']) === 'function' && this.element.validate.required() ) || ((typeof this.element.validate['required']) !== 'function' && this.element.validate.required )) {

                //log(["Picker is required", this.element.isAdvertContentEdit, this.element.value]);

                // If this is an advert edit, we are looking for a value to be set
                if(this.element.isAdvertContentEdit){
                    if(!this.element.value){
                        this.showError(lang.validate_required, showErrors);
                        return false;
                    }
                }
                else
                {
                    if (!this.element.selectedOptions || !Array.isArray(this.element.selectedOptions) || this.element.selectedOptions.length < 1) {
                        this.showError(lang.validate_required, showErrors);
                        return false;
                    }
                }
            }

            // if we have got this far then we haven't hit a validation error
            this.resetError();
            return true;
        },

        doLiveValidate() {
            if(Object.prototype.hasOwnProperty.call(this.element, "validate") && Object.hasOwnProperty.call(this.element.validate, "liveValidate")){
                return this.element.validate.liveValidate ? this.element.validate.liveValidate : false;
            }
            else
            {
                return false;
            }
        },
        liveValidate(){
            /**
             * Should we live validate this?
             */
            if(this.doLiveValidate()){
                this.element.validate.liveStatus = this.validate(false);
            }
        },

        /**
         * Error messages
         */
        showError (errorMessage, showErrors) {
            if(showErrors){
                this.error = errorMessage;
            }
        },
        resetError () {
            this.error = false;
        },

        /**
         * init change watch
         */
        initUserChangeWatch() {
            // log(["Init user change watch", this.element.name, this.element.type]);
            this.doFocusWatch = true;
        },
        onInputFocus(startValue) {
            if(this.doFocusWatch){
                this.startValue = JSON.stringify(startValue);
                //log(["FOCUS Change watch", this.startValue]);
            }
        },
        onInputBlur(endValue) {
            if(this.doFocusWatch) {
                // log(["BLUR Change watch Comparing current val with focus value", this.startValue, JSON.stringify(endValue)]);
                if (this.startValue !== JSON.stringify(endValue)) {
                    // log("CHANGE! Change watch");
                    this.$emit('user-input');
                }
                this.startValue = "";
            }
        },
        onInputFocusStandard() {
            this.onInputFocus(this.inputValue);
        },
        onInputBlurStandard() {
            this.onInputBlur(this.inputValue);
        },


    },
    mounted() {
        this.liveValidate();
        this.initUserChangeWatch();
    },
    updated() {
        this.liveValidate();
    },
    watch:{
        value: {
            handler: function() {
                this.liveValidate();
            },
        },
    }
}
